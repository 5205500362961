import React from "react";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { hideModal } from "../../../commons/hide-modal";
import CobeeButton from "../../../../app/components/CobeeButton";
import useStyles from "./styles";
import Modal from "../../../../app/components/modals/Modal";
import Table from "../../../../app/components/Table";
import { MonetaryAmount } from "../../../../app/domain/monetary-amount";

export default function BenefitBagDetailsModal({ benefitBag }) {
  const { container, row, buttonRow } = useStyles();

  const renderStateHistory = (stateHistory) => (
    <>
      <Grid item container xs={12} className={row}>
        <Typography variant="body2">
          <b>State History:</b>
        </Typography>
      </Grid>
      <Grid item container xs={12} className={row}>
        <Table
          columns={[
            { label: "Date", id: "date" },
            { label: "State", id: "state" },
          ]}
          data={stateHistory.map(({ timestamp, ...rest }) => ({
            ...rest,
            date: moment.unix(timestamp).format("YYYY-MM-DD HH:mm"),
          }))}
        />
      </Grid>
    </>
  );

  const textProperty = (label, value) => (
    <>
      <Grid item container xs={4} className={row}>
        <Typography variant="body2">
          <b>{label}:</b>
        </Typography>
      </Grid>
      <Grid item container xs={8} className={row}>
        <Typography variant="body2">{value}</Typography>
      </Grid>
    </>
  );

  const optionalAmount = (amount) =>
    amount ? MonetaryAmount.ofCurrency(amount).prettifyMoney({}) : "N/A";
  return (
    <Modal title="Benefit Bag Detail">
      <Grid item container xs={12} className={container}>
        <Grid item container xs={12} className={row}>
          <Typography variant="body2" component="h1">
            <h5>
              <b>Benefit Bag Instance</b>
            </h5>
          </Typography>
        </Grid>
        {textProperty("ID", benefitBag.benefitBagId)}
        {textProperty("Benefit Bag Config ID", benefitBag.benefitBagConfigId)}
        {textProperty("State", benefitBag.state)}
        {textProperty("Benefit Plan ID", benefitBag.benefitPlanId)}
        {textProperty("Consumable From", benefitBag.consumableFrom)}
        {textProperty("Available", optionalAmount(benefitBag.availableAmount))}
        {textProperty("Used", optionalAmount(benefitBag.usedAmount))}
        {textProperty("Limit", optionalAmount(benefitBag.limitAmount))}
        {textProperty(
          "Initial Limit Amount",
          optionalAmount(benefitBag.initialLimitAmount)
        )}
        {textProperty(
          "Accumulated From Previous Period",
          optionalAmount(benefitBag.accumulatedFromPreviousPeriod)
        )}
        {textProperty(
          "Remaining Amount When Cancelled",
          optionalAmount(benefitBag.remainingAmountWhenCancelled)
        )}
        {renderStateHistory(benefitBag.stateHistory)}
        <Grid item container xs={12} className={row}>
          <Typography variant="body2" component="h1">
            <h5>
              <b>Benefit Bag Config</b>
            </h5>
          </Typography>
        </Grid>
        {textProperty(
          "Deliver Unused Exempt To Purses",
          benefitBag.benefitBagConfig.deliverUnusedExemptToPurses.toString()
        )}
        {textProperty(
          "Renew At Period End",
          benefitBag.benefitBagConfig.renewAtPeriodEnd.toString()
        )}
        {textProperty(
          "Accumulable At Period End",
          benefitBag.benefitBagConfig.accumulableAtPeriodEnd.toString()
        )}
        {textProperty(
          "Period Duration In Payrolls",
          benefitBag.benefitBagConfig.periodDurationInPayrolls
        )}
        {textProperty(
          "Consumable From",
          benefitBag.benefitBagConfig.consumableFrom
        )}
        {textProperty("State", benefitBag.benefitBagConfig.state)}
        {textProperty(
          "Limit",
          optionalAmount(benefitBag.benefitBagConfig.limitAmount)
        )}
        <Grid item container xs={12} className={row}>
          <Typography variant="body2">
            <b>Benefits Allowed:</b>
          </Typography>
        </Grid>
        <Grid item container xs={12} className={row}>
          <Typography key={benefitBag.benefitBagId} component="lu">
            {benefitBag.benefitBagConfig?.allowedFor?.map((allowedFor) => (
              <Typography key={allowedFor} component="li">
                {allowedFor.benefit.replace("-benefit", "")}
              </Typography>
            ))}
            {benefitBag.benefitBagConfig?.customAllowedFor?.map(
              (customBenefit) => (
                <Typography key={customBenefit.id} component="li">
                  {customBenefit.name}
                </Typography>
              )
            )}
          </Typography>
        </Grid>
        {renderStateHistory(benefitBag.benefitBagConfig.stateHistory)}
        <Grid item container xs={12} className={buttonRow} justify="flex-end">
          <CobeeButton variant="secondary" onClick={hideModal}>
            Close
          </CobeeButton>
        </Grid>
      </Grid>
    </Modal>
  );
}
