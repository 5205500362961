import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { v4 } from "uuid";
import moment from "moment";
import { ClipLoader } from "react-spinners";
import { hideModal } from "../../../../usecases/commons/hide-modal";
import useStyles, { CustomCheckbox } from "./styles";
import CobeeButton from "../../CobeeButton";
import { MonetaryAmount } from "../../../domain/monetary-amount";
import CobeeTextField from "../../CobeeTextField";
import {
  findEmployeeAvailableBagAmount,
  findEmployeeAvailablePursesAmount,
} from "../../../../infrastructure/finance-repository";

export default function GenerateTransactionModal({
  isLoading,
  intendedAmount,
  employeeId,
  filterBenefits,
  payrollCycles,
  behaviours,
  defaultValues,
  unknownProcessorNotificationId,
  limits,
  callback,
}) {
  const [values, setValues] = useState(
    defaultValues || { payrollId: "", behaviours: [] }
  );
  const { currency } = intendedAmount;
  const [category, setCategory] = useState("");
  const [bagAmount, setBagAmount] = useState(MonetaryAmount.of(0, currency));
  const [pursesData, setPursesData] = useState({});
  const [allowance, setAllowance] = useState(MonetaryAmount.of(0, currency));
  const [allowanceNotExempt, setAllowanceNotExempt] = useState(
    MonetaryAmount.of(0, currency)
  );
  const [flex, setFlex] = useState(MonetaryAmount.of(0, currency));
  const [flexNotExempt, setFlexNotExempt] = useState(
    MonetaryAmount.of(0, currency)
  );
  const [limit, setLimit] = useState(undefined);

  const {
    container,
    row,
    header,
    buttonRow,
    select,
    behaviourRow,
    currencyContainer,
  } = useStyles();

  useEffect(() => {
    async function updateData() {
      const {
        data: { available: bagAmountData },
      } = await findEmployeeAvailableBagAmount({ employeeId, category });
      const purses = await findEmployeeAvailablePursesAmount({
        employeeId,
        category,
      });

      const limitForCategory =
        limits[category] !== undefined
          ? MonetaryAmount.ofCurrency(limits[category])
          : undefined;
      const allowanceData = intendedAmount.zero().add(purses.allowance);
      const allowanceNotExemptData = intendedAmount
        .zero()
        .add(purses["allowance-not-exempt"]);
      const flexData = intendedAmount.zero().add(purses.flex);
      const flexNotExemptData = intendedAmount
        .zero()
        .add(purses["flex-not-exempt"]);

      setPursesData({
        flex: flexData,
        "flex-not-exempt": flexNotExemptData,
        allowance: allowanceData,
        "allowance-not-exempt": allowanceNotExemptData,
      });
      setBagAmount(bagAmountData);
      setAllowance(allowanceData);
      setAllowanceNotExempt(allowanceNotExemptData);
      setFlex(flexData);
      setFlexNotExempt(flexNotExemptData);
      setLimit(limitForCategory);
    }
    if (category !== "") {
      updateData();
    }
  }, [category, employeeId, limits, intendedAmount]);

  const formatLabel = (time) => {
    if (time === null) {
      return "Current payroll";
    }
    if (time === -1) {
      return "No payroll";
    }
    return moment.unix(time).format("MMM YYYY");
  };

  const setPayrollId = (e) => {
    setValues({ ...values, payrollId: e.target.value });
  };

  const setBehaviours = (e) => {
    setValues({
      ...values,
      behaviours: e.target.value.map((behaviour) => ({
        behaviour,
        amount: MonetaryAmount.ofCurrency(intendedAmount).zero(),
        benefitBag: false,
        purse: false,
      })),
    });
  };

  const handleCheckbox = ({ index, behaviour, type: customType }) => {
    setValues({
      ...values,
      behaviours: values.behaviours.map((item, i) => {
        if (i === index && item.behaviour === behaviour) {
          return {
            ...item,
            purse: customType === "purse" ? !item[customType] : false,
            benefitBag: customType === "benefitBag" ? !item[customType] : false,
          };
        }
        return {
          ...item,
        };
      }),
    });
  };

  const handleAmountChange = (newAmount, index) => {
    setValues({
      ...values,
      behaviours: values.behaviours.map(({ amount, ...rest }, i) => ({
        ...rest,
        amount:
          i === index
            ? MonetaryAmount.fromUnitary(
                Number(newAmount),
                intendedAmount.currency
              )
            : amount,
      })),
    });
  };

  const shouldDisableContinue =
    category === "" ||
    values?.payrollId === "" ||
    values?.behaviours.length === 0;

  return (
    <Grid item container xs={12} className={container}>
      <Grid item container xs={12} className={`${row}, ${header}`}>
        <Typography variant="h5">Generate transaction</Typography>
        <Typography variant="h5">{intendedAmount.prettifyMoney()}</Typography>
      </Grid>
      <Grid item container xs={12} className={row}>
        <Typography variant="body2">Select a category</Typography>
        {isLoading ? (
          <ClipLoader size={16} color="#00458f" loading />
        ) : (
          <Select
            className={select}
            onChange={(e) => setCategory(e.target.value)}
            value={category}
          >
            {filterBenefits.map((benefit) => (
              <MenuItem key={v4()} value={benefit}>
                {benefit}
              </MenuItem>
            ))}
          </Select>
        )}
      </Grid>
      <Grid item container xs={12} className={row}>
        <Typography variant="h6">Available</Typography>
      </Grid>
      <Grid item container xs={12} className={row}>
        <Grid item container xs={6} className={row}>
          <Typography variant="body2">
            Total:
            <b>
              {limit ? MonetaryAmount.ofCurrency(limit).prettifyMoney({}) : "-"}
            </b>
          </Typography>
        </Grid>
        <Grid item container xs={6} className={row}>
          <Typography variant="body2">
            Bag:
            <b>
              {bagAmount
                ? MonetaryAmount.ofCurrency(bagAmount).prettifyMoney({})
                : "-"}
            </b>
          </Typography>
        </Grid>
      </Grid>

      <Grid item container xs={12} className={row}>
        <Grid item container xs={6} className={row}>
          <Typography variant="body2">
            Allowance: <b>{allowance ? allowance.prettifyMoney({}) : "-"}</b>
          </Typography>
        </Grid>

        <Grid item container xs={6} className={row}>
          <Typography variant="body2">
            Allowance not exempt:
            <b>
              {allowanceNotExempt ? allowanceNotExempt.prettifyMoney({}) : "-"}
            </b>
          </Typography>
        </Grid>
      </Grid>

      <Grid item container xs={12} className={row}>
        <Grid item container xs={6} className={row}>
          <Typography variant="body2">
            Flex: <b>{flex ? flex.prettifyMoney({}) : "-"}</b>
          </Typography>
        </Grid>

        <Grid item container xs={6} className={row}>
          <Typography variant="body2">
            Flex not exempt:
            <b>{flexNotExempt ? flexNotExempt.prettifyMoney({}) : "-"}</b>
          </Typography>
        </Grid>
      </Grid>

      <Grid item container xs={12} className={row}>
        <Grid item container xs={2} className={row}>
          <Typography variant="body2">Payroll cycle:</Typography>
        </Grid>

        <Grid item container xs={10} className={row}>
          <Select
            className={select}
            onChange={setPayrollId}
            disabled={!payrollCycles}
            value={values.payrollId}
          >
            {payrollCycles &&
              payrollCycles.map(({ endTimestamp, id }) => (
                <MenuItem key={v4()} value={id}>
                  {formatLabel(endTimestamp)}
                </MenuItem>
              ))}
          </Select>
        </Grid>
      </Grid>

      <Grid item container xs={2} className={row}>
        <Typography variant="body2">Behaviours:</Typography>
      </Grid>

      <Grid item container xs={10} className={row}>
        <Select
          className={select}
          onChange={setBehaviours}
          multiple
          disabled={!behaviours}
          value={values?.behaviours.map(({ behaviour }) => behaviour)}
          renderValue={(selected) =>
            selected.reduce(
              (acc, behaviour, i) =>
                `${acc}${behaviour}${i === selected.length - 1 ? "" : ", "}`,
              ""
            )
          }
        >
          {behaviours &&
            behaviours.map((behaviour) => (
              <MenuItem key={v4()} value={behaviour}>
                {behaviour}
              </MenuItem>
            ))}
        </Select>
      </Grid>

      {values.behaviours.map(({ behaviour, amount, benefitBag, purse }, i) => (
        <Grid
          item
          container
          xs={12}
          className={`${row} ${behaviourRow}`}
          key={behaviour}
        >
          <Grid item xs={2} container alignItems="center">
            <Typography variant="body2">{behaviour}:</Typography>
          </Grid>

          <Grid
            item
            xs={behaviour.includes("allowance") ? 3 : 6}
            container
            direction="row"
            alignItems="center"
          >
            <CobeeTextField
              type="number"
              defaultValue={MonetaryAmount.ofCurrency(amount).toUnitary()}
              minWidth="100%"
              min={0}
              onChange={(e) => handleAmountChange(e.target.value, i)}
            />
          </Grid>
          <Grid
            item
            xs={1}
            className={currencyContainer}
            container
            alignContent="center"
          >
            <Typography variant="body2">{amount.currency}</Typography>
          </Grid>

          {behaviour.includes("allowance") && (
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={benefitBag}
                    onChange={() =>
                      handleCheckbox({
                        index: i,
                        behaviour,
                        type: "benefitBag",
                      })
                    }
                    disabled={
                      bagAmount
                        ? MonetaryAmount.ofCurrency(bagAmount).isZero()
                        : true
                    }
                  />
                }
                label="From benefit bag"
              />
            </Grid>
          )}

          <Grid item container xs={3} alignItems="center" justify="flex-end">
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={purse}
                  onChange={() =>
                    handleCheckbox({ index: i, behaviour, type: "purse" })
                  }
                  disabled={
                    pursesData[behaviour]
                      ? pursesData[behaviour].isZero()
                      : true
                  }
                />
              }
              label="From purses"
            />
          </Grid>
        </Grid>
      ))}
      <Grid item container xs={12} className={buttonRow} justify="flex-end">
        <CobeeButton variant="secondary" onClick={hideModal}>
          Cancel
        </CobeeButton>
        <CobeeButton
          disabled={shouldDisableContinue}
          onClick={() =>
            callback({
              behaviours: values.behaviours,
              payrollId: values.payrollId,
              category,
              unknownProcessorNotificationId,
            })
          }
        >
          Accept
        </CobeeButton>
      </Grid>
    </Grid>
  );
}

GenerateTransactionModal.defaultProps = {
  isLoading: false,
  employeeId: "",
  filterBenefits: [],
  payrollCycles: [],
  behaviours: [],
  defaultValues: null,
  unknownProcessorNotificationId: "",
  intendedAmount: MonetaryAmount.ZERO,
  limits: {},
  callback: () => {},
};

GenerateTransactionModal.propTypes = {
  isLoading: PropTypes.bool,
  employeeId: PropTypes.string,
  filterBenefits: PropTypes.array,
  payrollCycles: PropTypes.array,
  behaviours: PropTypes.array,
  defaultValues: PropTypes.object,
  unknownProcessorNotificationId: PropTypes.string,
  intendedAmount: PropTypes.object,
  callback: PropTypes.func,
  limits: PropTypes.object,
};
